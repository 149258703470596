
import React from 'react';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import QRCode from 'react-qr-code';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { Pagination } from "antd";
import dateFormat from "dateformat";
const Deposit = () => {
    const [data, setData] = useState('');
    const [desposit, setDesposit] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currency, setCurrency] = useState('');
    const handledashboard = async () => {
        try {
            let response = await _fetch(`${base_url}api/user/depositQrCode`, 'get');
            console.log('Fetched data:', response);
            if (response.success) {
                setData(response.data);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    };

    const handledesposit = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize || 0;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
            };

            console.log(`Fetching deposits: page=${page}, pageSize=${pageSize}, skip=${skip}`);

            let response = await _fetch(`${base_url}api/user/deposit`, 'post', requestData);
            console.log('response ', response);
            if (response.success === true) {
                setDesposit(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    };

    useEffect(() => {
        handledashboard();
        handledesposit(currentPage, 10);
        const interval = setInterval(() => {
            handledesposit(currentPage, 10); // Refresh deposit data
        }, 120000); // 5000ms = 5 seconds

        // Clear the interval when the component is unmounted
        return () => clearInterval(interval);
    }, [currentPage]);

    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data)
                .then(() => {
                    toast.dismiss()
                    toast.success('Address copied to clipboard!');
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = data;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            toast.dismiss()
            toast.success('Address copied to clipboard (fallback method)!');
        }
    };
    const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);

    };


    const records = () => {
        if (desposit && desposit?.data && desposit?.data?.length > 0) {
            return desposit?.data?.map((row, i) => {
                const serialNumber = pageSer + i + 1;
                return (
                    <>
                        <div className="container p-2">
                            <div className="card p-2">

                                <div className="card-body">
                                    <div className="row openPositionsDiv">
                                        <div className="col-md-6">
                                            <dl className="row mb-0 positionRow">
                                                <dt className="col-5">Token Name</dt>

                                                <dd className="col-7"><small>USDT</small></dd>
                                                <dt className="col-5">Amount</dt>
                                                <dd className={`col-7 ${row?.amount < 0 ? 'text-danger' : 'text-success'}`}>{row?.amount}</dd>
                                                <dt className="col-5">To</dt>
                                                <dd className="col-7">{row?.to}</dd>

                                            </dl>

                                        </div>
                                        <div className="col-md-6">
                                            <dl className="row mb-0 positionRow">
                                                <dt className="col-5">Created At</dt>
                                                <dd className="col-7">{dateFormat(row?.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                                                <dt className="col-5">From</dt>
                                                <dd className="col-7">{row?.from}</dd>


                                            </dl>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </>

                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="13" className='text-center'>Data Not Found</td>
                </tr>
            );
        }
    };
    return (
        <>
            <div className="row mt-3">
                <div className="main-qr  text-center">
                    <div className="mb-3 mt-5">
                        <h3 className='scan text-center'>Scan the QR code to get address</h3>
                    </div>
                    <div className='qr-2'>
                        {data ? (
                            <QRCode className="w-50" value={data} />
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                    <h3 className='scan-2 text-center'>Or</h3>
                    <h3 className='scan-copy text-center mt-2'>
                        {data}
                        <i className="bi bi-copy copy-btnn" onClick={copyToClipboard}></i>
                    </h3>
                    <button className="btn btn-secondary mt-4" type="button" onClick={handledesposit}>
                        Deposit Now
                    </button>
                </div>


                <div className="section2">

                    <div className="table-sec">
                        <div className="transactions">





                            {records()}

                            <div className="pagination">
                                <Pagination
                                    style={{ cursor: "pointer" }}
                                    className="mx-auto"
                                    current={currentPage || 1}
                                    total={data?.count || 0}
                                    pageSize={10}
                                    onChange={handlePaginationChange}
                                    showSizeChanger={false}
                                    showQuickJumper={false}
                                />

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default Deposit;
