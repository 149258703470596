// import React from 'react'

// const Market = () => {
//   return (
//    <>
//    </>
//   )
// }

// export default Market


import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { NavLink, useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import moment from 'moment';
import enter from '../../src/assets/img/enter.png'
import copy from '../../src/assets/img/copy.png'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { Pagination } from "antd";

const Market = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,


      };

      let response = await _fetch(`${base_url}api/user/gainers`, 'GET', {});
      console.log('response ', response)
      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }




  // useEffect(() => {
  //   handledashboard(currentPage, 10);
  // }, [currentPage]);
  // const handlePaginationChange = (pageOne) => {
  //   setCurrentPage(pageOne);

  // };

  console.log('data', data);


  useEffect(() => {
    handledashboard();
  }, []);
  // const handlePaginationChange = (pageOne) => {
  //   setCurrentPage(pageOne);

  // };
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <>
            <tr >
              <td className='td-1'>
                <div className='markit-td1'>
                  {/* <img src='https://res.cloudinary.com/dp2jq1xi2/image/upload/v1715503973/exchange/depositToken/bspgcgvyfmpnwvah0vng.png' alt="" /> */}
                  <span class="coin-name">{row?.symbol}</span>
                  {/* <span className='coin-name-1'>{row?.symbol}</span> */}
                  {/* <span className='coin-tag-name'>1</span> */}
                </div>
              </td>
              <td className='upp-2'>{row?.lastPrice}</td>
              {/* <td className='up-trade '>578 <span>up</span></td> */}
              {/* <td className='down-trade '>56456 <span></span></td> */}


              <td className='up-trade '>{row?.openPrice} <span><FaLongArrowAltUp /></span></td>
              {/* <td className='down-trade '><span>655<FaLongArrowAltDown className='' /></span></td> */}


              <td className='up-trade '> {row?.priceChangePercent}<span><FaLongArrowAltUp /></span></td>
              {/* <td className='down-trade '>445 <span><FaLongArrowAltDown className='' /></span></td> */}

              {/* <td className='action-btn'>
                                                    <NavLink to={`/trade?symbol=${item.symbol}`}>Trade</NavLink>
                                                    {/* {/ <NavLink to={`/trade?symbol=${item.symbol}`} className="ms-2">Convert</NavLink> /}
                                                    <NavLink to={`/trade?symbol=${item.symbol} `} className="ms-2">Buy/Sell</NavLink>
                                                </td> */}
            </tr>



          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center'>Data Not Found</td>
        </tr>
      );
    }
  };
  return (
    <>
      {/* <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5">
              <div className="card start-date">
                <div className='row'>






                </div>
              </div>
            </div>

            {records()}

            {/* <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div> 
          </div>

        </div>
      </div> */}
      <div class="container mt-2  mb-4">
        <div className='col-md-12'>
          <div className='market-table'>
            <table className="table-responsive mb-4">
              <thead>
                <tr>
                  <th className='text-left symbol' scope="col">Symbol</th>
                  <th scope="col">Last Price</th>
                  <th scope="col">Open Price</th>
                  <th scope="col">Price Percent</th>
                  {/* <th scope="col">24 Hour Price</th> */}
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {/* */}
                {records()}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </>
  )
}

export default Market
