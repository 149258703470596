// import React from 'react'

// const Transfer = () => {
//   return (
//     <>
//     </>
//   )
// }

// export default Transfer

import React, { useState } from 'react'
import '../assets/css/Style.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { base_url } from '../Config/config'
import _fetch from '../Config/api'
import toast from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner';
import businessman from '../assets/img/businessman.jpg'
import logo from '../assets/img/logo.png'
const Transfer = () => {
  const [fromWallet, setFromWallet] = useState('INCOME')
  const [toWallet, setToWallet] = useState('WALLET')
  const [amount, setAmount] = useState()

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("email", email);
    // console.log("password", password);
    if (!fromWallet || !toWallet || !amount) {
      toast.dismiss()
      toast.error("Please enter the details");
      return;
    }
    setLoading(true);
    try {
      const requestData = {
        fromWallet, toWallet, amount
      };
      let response = await _fetch(`${base_url}api/user/transfer`, 'POST', requestData, {})

      // console.log("data", response.data?.accessToken);
      if (response.success === true) {


        // localStorage.setItem("token", response.token)
        // console.log('token', response.data?.token);

        toast.success(response.message)
        setFromWallet('')
        setToWallet('')
        setAmount('')
        // navigate('/dashboard')
      } else {
        toast.error(response.message)
      }

    }
    catch (error) {
      console.error('Error details:', error);
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <div className="row justify-content-center p-3">
        <div className="mt-5 w-full transfer-height">
          <div className="bind_details mt-2">
            <div className="rounded  p-2">
              <h3 className='scan text-center'>Transfer</h3>
              <div className="col mt-5">
                <div className="heading">
                  {/* <h5>Precautions</h5> */}
                </div>
                <div className="heading-data">
                  <form className='p-1' onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">From </label>
                      <input type="text" id="email" value={fromWallet} onChange={(e) => setFromWallet(e.target.value)} className="form-control" autoComplete='off' aria-describedby="emailHelp" />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="exampleInputPassword1" className="form-label">to</label>
                      {/* <input type="password" id='password' value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" /> */}

                      <div class="input_eye">
                        <div className='in_put_f'>
                          <input
                            type='text'
                            id='password'
                            value={toWallet}
                            onChange={(e) => setToWallet(e.target.value)}
                            className="form-control "
                          />

                        </div>

                        {/* <div className='eye_sec'>
                                            {/* <span
                                                className={`password-toggle ${showPassword ? 'active' : ''}`}
                                                onClick={() => setShowPassword(!showPassword)}

                                            >
                                                {showPassword ? (
                                                    <i className="fas fa-eye-slash"></i>
                                                ) : (
                                                    <i className="fas fa-eye"></i>
                                                )}
                                            </span>
                                        </div> */}

                      </div>



                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">Amount </label>
                      <input type="number" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} className="form-control" autoComplete='off' aria-describedby="emailHelp" />
                    </div>
                    {/* <div className="mb-3">
                                    <div className="forget fw-bold mt-2 pt-1 mb-0 text-right">
                                        <div className="link-danger">
                                            < NavLink to="/Verfication_forget" className="link-danger" >Forgot Password?</NavLink>
                                        </div>
                                    </div>
                                </div> */}
                    <button type="submit" className="btn btn-primary d-flex m-auto">
                      {loading ? (
                        <ColorRing
                          height={20}
                          width={20}
                          color="#fff"
                          ariaLabel="loading"
                        />
                      ) : (
                        'Submit'
                      )}</button>

                    <div>
                    </div>
                  </form>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </>
  )
}

export default Transfer