import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import '../assets/css/Style.css';
import '../assets/css/pStyle.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router';
import LogoutModal from '../Auth/LogoutModal';
import logo from '../assets/img/logo.png'
import logo1 from '../assets/img/logo1.png'

const Sidebar = () => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [openSection, setOpenSection] = useState(null);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);



    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const logout = () => {
        localStorage.clear('token');
        navigate('/Login');
    };


    const handleSectionToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
    let changeToggle = () => {
        // alert('clicked')
        if (sidebar)
            setSidebar(false)
        else
            setSidebar(true)
    }

    return (
        <>
            <div id="home" className="w3-container city mt-100">
                <div className="navbar-fixed">
                    <div className="navbar__content">
                        <div className="navbar__content-left">
                            <div className="">
                                <NavLink to='/'><img className="welcome" src={logo} alt="" /></NavLink>
                            </div>
                            <div className="text-dark">
                                {/* <span className="admin_1 text-dark">Welcome Back!</span> */}
                                <h6 className="welcome text-dark">{process.env.PROJECT_NAME}</h6>
                            </div>
                        </div>
                        <div className="navbar__content-center">
                            <div className="navbar__content-title"></div>
                        </div>
                        <div className="navbar__content-right">
                            <div className="content__right">
                                <NavLink to='/transfer'><i class="fa-solid fa-money-bill-transfer"></i></NavLink>
                                <NavLink to='/dashboard'>  <i class="fa-solid fa-backward fa-beat"></i></NavLink>
                                {/* <i class="fa-solid fa-right-from-bracket" onClick={logout} ></i> */}
                                {/* <i className='btn btn-danger ' onClick={() => setIsLogoutModalOpen(true)} type='button'>LOGOUT</i> */}
                                {/* <button className="btn btn-default" onClick={changeToggle}>
                                    <i className="fas fa-bars text-dark"></i>
                                </button> */}
                                {/* <button className='btn btn-danger ' onClick={() => setIsLogoutModalOpen(true)} type='button'> <i class="fa-solid fa-right-from-bracket"></i></button> */}
                                {/* <LogoutModal
        isOpen={isLogoutModalOpen}
        onCancel={() => setIsLogoutModalOpen(false)}
        onConfirm={() => {
          setIsLogoutModalOpen(false);
          logout();
        }}
      /> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div id="wrapper" className={sidebar ? "toggled" : ""}>
                    <div id="sidebar-wrapper" className="sidebar s-left">
                        <ul className="sidebar-nav mt-3">
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/open_position">Traders</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/bindapi">Bind Api</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/deposit">Deposit</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/close_position">closeposition</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/activity">Activity</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/openspotposition"> spot position trader</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/closespotposition">close spot position</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/deposit">Deposit</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/key.png" />
                                    <a href="#">Change password</a>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Sidebar;
