import React, { useState } from 'react'
import '../assets/css/Style.css'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { base_url } from '../Config/config'
import _fetch from '../Config/api'
import toast from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner';
import businessman from '../assets/img/businessman.jpg'
import logo from '../assets/img/logo.png'


const Forget = () => {
    const { state } = useLocation();
    const [email, setEmail] = useState(state?.email || "");
    const [password, setPassword] = useState();
    const [otp, setOtp] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);
    const [passwordconfirm, setPasswordConfirm] = useState();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!email || !password || !otp || !passwordconfirm) {
            toast.dismiss()
            toast.error("Please enter the details");
            return;
        }
        setLoading(true);

        try {
            const ForgetData = {
                email: email,
                password: password,
                otp: otp,
                confirmpassword: passwordconfirm,
            };
            let response = await _fetch(`${base_url}api/user/forgotePassword`, 'POST', ForgetData, {})

            // console.log("response", response);
            if (response.success === true) {
                toast.success(response.message);
                navigate('/');
            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);

        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center main-box-sing ">
                    <div className="sign-page ios ">
                        <div className="heading text-center ">
                            <NavLink to='/'>   <i class="fa-solid fa-arrow-left dashboard-3 " style={{ alignItems: "center" }}></i> </NavLink>
                            <div className='d-flex'>

                                <NavLink to='/'><img className="welcome" src={logo} alt="" />

                                </NavLink>
                            </div>
                            <img className="image_border mt-3" src={businessman} alt="..." />
                        </div>
                        <form >
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" readOnly />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">OTP </label>
                                <input type="text" id="name" onChange={(e) => setOtp(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                {/* <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} autoComplete='off' className="form-control" /> */}
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                />
                                <span
                                    className={`password-toggle ${showPassword ? 'active' : ''}`}
                                    onClick={() => setShowPassword(!showPassword)}

                                >
                                    {showPassword ? (
                                        <i className="fas fa-eye-slash"></i>
                                    ) : (
                                        <i className="fas fa-eye"></i>
                                    )}
                                </span>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Confirm Password</label>
                                {/* <input type="password" id="sponser" onChange={(e) => setPasswordConfirm(e.target.value)} autoComplete='off' className="form-control" /> */}
                                <input
                                    type={showPasswords ? 'text' : 'password'}
                                    id='password'
                                    value={passwordconfirm}
                                    onChange={(e) => setPasswordConfirm(e.target.value)}
                                    className="form-control"
                                />
                                <span
                                    className={`password-toggle ${showPasswords ? 'active' : ''}`}
                                    onClick={() => setShowPasswords(!showPasswords)}

                                >
                                    {showPasswords ? (
                                        <i className="fas fa-eye-slash"></i>
                                    ) : (
                                        <i className="fas fa-eye"></i>
                                    )}
                                </span>
                            </div>
                            <button type="submit" className="btn btn-primary m-auto d-block" onClick={handleSignUp}>
                                {loading ? (
                                    <ColorRing
                                        height={20}
                                        width={20}
                                        color="#fff"
                                        ariaLabel="loading"
                                    />
                                ) : (
                                    'Submit'
                                )}
                            </button>
                            <div>
                                <div className="small fw-bold mt-2 pt-1 mb-0 text-center">
                                    Have an account? <NavLink to='/Login' className="link-danger">Login</NavLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forget