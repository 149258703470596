import React, { useEffect, useState } from 'react'
import icon from "../assets/img/icon.svg"
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import plug from '../../src/assets/img/renewable-energy.gif'
import profit from '../../src/assets/img/profit.gif'
import activity from '../../src/assets/img/activity.gif'
import unscreen from '../assets/img/unscreen.gif'
import moment from 'moment';
import trading from '../assets/img/trading.png'
import cropunscreen from '../assets/img/cropunscreen.gif'
import spot from '../assets/img/spot.gif'
import spottrade from '../assets/img/spottrade.gif'
import { NavLink } from 'react-router-dom'
import businessman from '../assets/img/businessman.jpg'
import stockmarket from '../assets/img/stockmarket.jpg'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import dateFormat from "dateformat";
import Market from './Market'
// import ModalPop from './Success'
const Dashboard = () => {
  const [data, setData] = useState({});
  const [banner, setBanner] = useState('');
  const [name, setName] = useState({});
  const [count, setCount] = useState('')
  const [binanceBalance, setBinanceBalance] = useState(0)
  const [paidStatus, setPaidStatus] = useState('')
  const [packageamount, setPackageamount] = useState('')
  const [packageValiditys, setPackageValiditys] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  // const [showModal, setShowModal] = useState(false);
  const [packageValidity, setPackageValidity] = useState('')
  const [news, setNews] = useState('')
  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        setData(response?.data?.balance);
        setName(response?.data.user);
        setCount(response?.data)
        setBinanceBalance(parseFloat(response?.data?.binanceBalance))
        setPaidStatus(response?.data?.user.paidStatus)
        setPackageamount(response?.data?.user?.packageAmount)
        setPackageValiditys(response?.data?.user?.packageValidity)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }

  const handleactivite = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/packages`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        setPackageValidity(response?.data)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  const handleactiviteuser = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/activateAccount`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        toast.dismiss()
        // setPackageValidity(response?.data)
        toast.success(response.message)
        handledashboard()
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }

  const handlenews = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/getNews`, 'get', {});
      console.log('resp is ', response)
      if (response.success == true) {
        toast.dismiss()
        // setPackageValidity(response?.data)

        setNews(response?.data?.news)

      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  // getBanners 
  console.log('news', news);

  const handlebanner = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,

      };

      let response = await _fetch(`${base_url}api/user/getBanners`, 'post', requestData);

      if (response.success === true) {
        setBanner(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };


  useEffect(() => {
    handledashboard();
    handleactivite();
    handlebanner(currentPage, 10);
    handlenews()
    // const hasSeenModal = localStorage.getItem('hasSeenModal'); // Check if modal has already been shown

    //     if (!hasSeenModal) {
    //         setShowModal(true);
    //         localStorage.setItem('hasSeenModal', 'true');
    //     }
  }, [currentPage]);



  console.log('banner-=-=-=-=-=-', banner);

  const recordsbanner = () => {
    if (banner && banner.data && banner.data.length > 0) {
      return (
        <div className="container mt-1 p-0">
          <div id="carouselExampleCaptions" className="carousel slide phonepe-carousel" data-bs-ride="carousel" data-bs-interval="3000">
            <div className="carousel-indicators">
              {banner.data.map((_, i) => (
                <button
                  key={i}
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to={i}
                  className={i === 0 ? 'active' : ''}
                  aria-label={`Slide ${i + 1}`}
                  aria-current={i === 0 ? 'true' : 'false'}
                ></button>
              ))}
            </div>
            <div className="carousel-inner">
              {banner.data.map((row, i) => (
                <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
                  <img className="image_border" src={row.image} alt="..." />
                  <div className="carousel-caption d-md-block">
                    {/* <h5>{row.title || 'Title'}</h5> */}
                  </div>
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p className='text-center text-light mt-5'>Data Not Found</p>
        </div>
      );
    }
  };
  // const handleClose = () => setShowModal(false);
  return (
    <>
      {/* <div className=''>
                <ModalPop open={showModal} handleClose={handleClose} />
            </div> */}
      <div className='main-trade'>
        <div className="container mt-5">
          {recordsbanner()}
        </div>
        <div className="container mt-2 p-1">
          <div className='main-boz p-1'>
            <div className='row text-h2 m-auto'>

              <div className='col-lg-6 col-md-6 col-sm-12 col-12 p-1'>
                <div className='box_card1'>
                  <div className='hii'>
                    <h6>Registered At <b> : {dateFormat(name?.createdAt, "yyyy-mm-dd, hh:mm:ss")}</b> </h6>
                    <h6>Bot Activation Date <b> : {dateFormat(name?.botActivationDate, "yyyy-mm-dd")}</b></h6>
                    <h6>Sponsor Id <b> : {name?.sponserId || "N/A"}</b></h6>
                    <h6>User Id <b> : {name?.userId || "N/A"}</b></h6>
                    <h6>User Name <b> : {name?.name || "N/A"}</b></h6>
                  </div>
                  <div className='hii2'>
                    <h6>Bot Activation Fees <b> : ${packageamount}</b></h6>
                    <h6>Bot Validity<b> : {packageValiditys}</b></h6>
                    <h6>Direct Referral Team <b> : {count?.directsCount || "N/A"}</b></h6>
                    <h6>Total Team <b> : {count?.teamCount || "N/A"}</b></h6>
                    <h6>Exchange Balance <b> : ${binanceBalance.toFixed(2)}</b></h6>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 col-12 p-1'>
                <div className='box_card2'>
                  <div className='hii'>
                    <h6 className='' >Daily Trade Profit <b> : ${count?.last24hourProfit}</b></h6>
                    <h6 className='' >Direct Team Bonus <b> : ${count?.directTeamBonus?.toFixed(2)}</b></h6>
                    <h6 className='' >Club Bonus : <b> ${count?.clubBonus?.toFixed(2)}</b></h6>
                    <h6 className='' >Loyalty Bonus : <b> ${count?.loyalityBonus?.toFixed(2)}</b></h6>
                    <h6 className='' >Fuel Wallet <b> : ${count?.mainBalance?.toFixed(2)}</b></h6>
                  </div>
                  <div className='hii2'>
                    <h6 className='' >Working Bonus Wallet : <b> ${count?.incomeBalance?.toFixed(2)}</b></h6>
                    <h6 className='' >Direct Team Business : <b> ${count?.directsActive?.toFixed(2)} </b></h6>
                    <h6 className='' >Total Team Business : <b> ${count?.teamActive?.toFixed(2)} </b></h6>
                    <h6 className='' >Total Team Bonus : <b> 0 </b></h6>
                    <h6 className='' >Total Trade Profit : <b> ${count?.overAllPnl?.toFixed(2)}</b></h6>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>


        <div className="container mt-2">
          <h3 className='marque'><marquee>{news} </marquee></h3>


          {!paidStatus && (
            <>
              <div class="true-false m-auto mt-3">
                <div className='true-fals-2 p-1'>
                  <button type="submit" className="button flashikig-btn" onClick={handleactiviteuser}>Activate Account </button>
                  <div className='click-me'>

                    <p className='pack-amount'> Bot Activation Fees: ${packageValidity?.packageAmount}</p>
                    <p className='pack-amount'> Bot Validity:-{packageValidity?.packageValidity} days</p>
                  </div>



                </div>
              </div>
            </>

          )}
          {/* </div>


          </div>
        </div> */}
        </div>


        <div class="container mt-2">
          <div class="">
            <div class="col-xl-12">
              <div class="main-btn p-0">
                {/* <h2 class="Money-transfer">Transfer Money</h2> */}
                <ul>
                  <li class="btnn_blue">
                    <div class="image_border p-0">
                      <NavLink to="/tranctattion" className="inner-btn">
                        <img className="to-mobile" src={plug} alt="" />
                      </NavLink>
                    </div>
                    <NavLink to="/tranctattion" className="inner-btn">
                      <span className="text-light text-center">Transaction</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border  p-0">
                      <NavLink to="/activity" className="inner-btn">
                        <img src={activity} alt="" className="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/activity" className="inner-btn">
                      <span className="text-light text-center">Activites</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border p-0">
                      <NavLink to="/deposit" class="inner-btn">

                        <img src={unscreen} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/deposit" class="inner-btn">
                      <span class="text-light text-center">Fuel Deposit</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border p-0">
                      <NavLink to="/DashBonus" class="inner-btn">
                        <img src={trading} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/DashBonus" class="inner-btn">
                      <span class="text-light text-center">Bonus</span>
                    </NavLink>
                  </li>
                </ul>
                <ul className='mt-2'>
                  <li class="btnn_blue">
                    <div class="image_border   p-0">
                      <NavLink to="/close_position" className="inner-btn">
                        <img className="to-mobile" src={spottrade} alt="" />
                      </NavLink>
                    </div>
                    <NavLink to="/close_position" className="inner-btn">
                      <span className="text-light text-center">position</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border   p-0">
                      <NavLink to='/fundtransfer' class="inner-btn">
                        <img src={profit} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/fundtransfer" class="inner-btn">
                      <span class="text-light text-center">Fund Transfer</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border  p-0">
                      <NavLink to="/wallet" className="inner-btn">
                        <img src={spot} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/wallet" className="inner-btn">
                      <span className="text-light text-center">Wallet</span>
                    </NavLink>
                  </li>
                  <li class="btnn_blue">
                    <div class="image_border  p-0">
                      <NavLink to="/mithdraw" class="inner-btn">
                        <img src={cropunscreen} alt="" class="img-fluid" />
                      </NavLink>
                    </div>
                    <NavLink to="/mithdraw" class="inner-btn">
                      <span class="text-light text-center">Withdraw</span>
                    </NavLink>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
        {/* <div class="container mt-2  mb-4">
          <div className='col-md-12'>
            <div className='market-table'>
              <table className="table-responsive mb-4">
                <thead>
                  <tr>
                    <th scope="col">Coin Name</th>
                    <th scope="col">Coin Price (USDT)</th>
                    <th scope="col">1 Hour Price</th>
                    <th scope="col">7 Hour Price</th>
                    <th scope="col">24 Hour Price</th>
                    {/* <th scope="col">Action</th> 
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td className='td-1'>
                      <div className='markit-td1'>
                        <img src='https://res.cloudinary.com/dp2jq1xi2/image/upload/v1715503973/exchange/depositToken/bspgcgvyfmpnwvah0vng.png' alt="" />
                        <span class="coin-name">Bitcoin</span>
                        <span className='coin-name-1'>BTC</span>
                        {/* <span className='coin-tag-name'>1</span> *
                      </div>
                    </td>
                    <td className='upp-2'>570</td>
                    <td className='up-trade '>578 <span>up</span></td>
                    {/* <td className='down-trade '>56456 <span></span></td> 


                    <td className='up-trade '>6565 <span><FaLongArrowAltUp /></span></td>
                    {/* <td className='down-trade '><span>655<FaLongArrowAltDown className='' /></span></td> 


                    <td className='up-trade '> 3453<span><FaLongArrowAltUp /></span></td>
                    {/* <td className='down-trade '>445 <span><FaLongArrowAltDown className='' /></span></td>

                    {/* <td className='action-btn'>
                                                    <NavLink to={`/trade?symbol=${item.symbol}`}>Trade</NavLink>
                                                    {/* {/ <NavLink to={`/trade?symbol=${item.symbol}`} className="ms-2">Convert</NavLink> /}
                                                    <NavLink to={`/trade?symbol=${item.symbol} `} className="ms-2">Buy/Sell</NavLink>
                                                </td> 
                  </tr>
                  <tr >
                    <td className='td-1'>
                      <div className='markit-td1'>
                        <img src='https://res.cloudinary.com/dp2jq1xi2/image/upload/v1715499335/exchange/depositToken/n9hx7scojtbhwuktemby.png' alt="" />
                        <span class="coin-name">XRP</span>
                        <span className='coin-name-1'>XRP</span>
                        {/* <span className='coin-tag-name'>1</span> 
                      </div>
                    </td>
                    <td className='upp-2'>570</td>
                    <td className='up-trade '>578 <span>up</span></td>
                    {/* <td className='down-trade '>56456 <span></span></td> 


                    <td className='up-trade '>6565 <span><FaLongArrowAltUp /></span></td>
                    {/* <td className='down-trade '><span>655<FaLongArrowAltDown className='' /></span></td> *


                    <td className='up-trade '> 3453<span><FaLongArrowAltDown className='' /></span></td>
                    {/* <td className='down-trade '>445 <span><FaLongArrowAltDown className='' /></span></td> 

                    {/* <td className='action-btn'>
                                                    <NavLink to={`/trade?symbol=${item.symbol}`}>Trade</NavLink>
                                                    {/* {/ <NavLink to={`/trade?symbol=${item.symbol}`} className="ms-2">Convert</NavLink> /}
                                                    <NavLink to={`/trade?symbol=${item.symbol} `} className="ms-2">Buy/Sell</NavLink>
                                                </td> 
                  </tr>
                
                </tbody>
              </table>
            </div>
          </div>

        </div> */}
        <Market />
      </div>


    </>
  )
}

export default Dashboard;
